html, body, #root {
    height: 100%;
    padding: 0;
    margin: 0;
    font-size: 17px;
    width: 100%;
    max-width: 100%;
}

#root {
    display: flex;
    flex-direction: column;
}


.adm-search-bar * {
    height: 50px !important;
    font-size: 20px !important;
}

.adm-search-bar-input-box-icon, .adm-input-clear {
    color: #2a2a2a !important;
}

.adm-modal-body, .adm-modal-content {
    max-height: 90vh;
}

.adm-center-popup-wrap {
    min-width: 95% !important;
}

.adm-center-popup-body {
    max-width: 100% !important;
    min-width: 100% !important;
    width: 100% !important;;
}

.adm-tab-bar-item-active {
    color: #047d95;
}

.navigate-anchor {
    color: #339af0;
    cursor: pointer;
}

.navigate-anchor:hover {
    text-decoration: underline
}

.fullWidthListItem .mantine-List-itemWrapper{
    width: 80%;
}

@media print {
    .no-print, .no-print * {
        display: none !important;
    }

    .no-break {
        margin-top: 40px;
        break-inside: avoid;
        position: relative;
        min-height: 90vh;
    }

    .newPage {
        page-break-before: always
    }

    .print-mt-0{
        margin-top: 0;
    }

    .print-table{
        border: none;
    }

    .print-mt-60{
        margin-top: -60px;
    }

    .print-50-perc *{
        font-size: 10px!important;
    }
    .print-50-perc td{
        padding: 2px!important;
    }

}

@media screen {
    div.page-number {
        display: none;
    }
}

@media print {
    div.page-number {
        position: absolute;
        bottom: 0;
        left: 0;
    }
}

table {
    table-layout: fixed;
    overflow-wrap: break-word;
}

.hoverG:hover{
    background: lightgray;
    color: #2a2a2a;
}

.selectableTable table tr td:first-child, .selectableTable table tr th:first-child{
    width: 35px;
}

